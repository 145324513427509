/* eslint-disable no-unused-vars */
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Button, IconButton, Toolbar, Hidden } from "@material-ui/core";
import logoExperta from "assets/images/logos/logo-art.png";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";

import useRouter from "utils/useRouter";

// export const ReactLogo = () => {
//   return(
//     <img src={logo} 
//       alt="logo experta"
//     />
//   )
// }

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TopBar = (props) => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();

  const handleLogout = () => {
    localStorage.clear();
    history.push("/login");
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="LogoExperta"
            src={logoExperta}
            style={{
              width:'110px',
            }}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {/* <Hidden mdDown> */}
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
        </Button>
        {/* </Hidden> */}
        {/* <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
