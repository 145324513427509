/* eslint-disable no-unused-vars */
import jwtDecode from "jwt-decode";

const setCredentials = (credentials) => {
  if (credentials) {
    return localStorage.setItem("currentUser", JSON.stringify(credentials));
  } else {
    return localStorage.clear;
  }
  // credentials
  //   ? localStorage.setItem("currentUser", JSON.stringify(credentials))
  //   : localStorage.clear;
};

const getLoggedInUser = () => {
  const currentUser = localStorage.getItem("currentUser");

  return currentUser
    ? typeof currentUser === "object"
      ? currentUser
      : JSON.parse(currentUser)
    : null;
};

const isUserAuthenticated = () => {
  /* const credentials = getLoggedInUser();

  if (!credentials) return false;

  const token = credentials.token.accessToken;

  const decodeToken = jwtDecode(token);

  const currentTime = Date.now() / 1000;

  if (decodeToken.exp < currentTime) {
    return false;
  } else {
    return true;
  } */
  return true;
};

export { setCredentials, getLoggedInUser, isUserAuthenticated };
