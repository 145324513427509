import axios from "./axios";

const getMessages = (userId) => {
  const BEARER = 'Bearer '.concat(123456789);
  return axios.get(`notification/user/${userId}` , {headers: {
    Authorization: BEARER
  },
  // This timeout avoid timeout error
  timeout: 30000
  })
};

const getMessageById = (notificationId) => {
  return axios.get(`notification/${notificationId}`);
};

const changeMessageStatus = (notificationId) => {
  return axios.post(`notification/read/${notificationId}`);
};

export default {
  getMessages,
  getMessageById,
  changeMessageStatus,
};
