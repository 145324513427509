// AUTH
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const AUTH_CLEAR = "AUTH_CLEAR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const FAIL_CHANGE_PASSWORD = "FAIL_CHANGE_PASSWORD";
export const ACCEPT_CONDITIONS = "ACCEPT_CONDITIONS";
export const GET_USER_INFO = "GET_USER_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_CONDITION_ACCEPTED = "SET_CONDITION_ACCEPTED";
export const ONBOARDING_COMPLETE = "ONBOARDING_COMPLETE";