import { call, put, takeEvery } from "redux-saga/effects";

import * as userActions from "../actions/userActions";
import * as authActions from "../actions/authActions";

import { CHANGE_PASSWORD, GET_USER_INFO, ONBOARDING_COMPLETE } from "../types";
import { userService } from "services";

function* changePassword({ payload: { values, history } }) {
  try {
    yield call(userService.changePassword, values);
    history.push("/login")
  } catch (error) {
    yield put(userActions.failChangePasswordAction(error.response.data));
  }
}

function* getUserInfo({ payload: { values } }) {
  try {
    const response = yield call(userService.getUserInfo, values);
    const { data } = response;
    yield put( authActions.setUserInfo(data));
  } catch (error) {
    yield put(userActions.failChangePasswordAction(error.response.data));
  }
}

function* onboardingComplete({ payload: { values } }) {
  try {
    // const response = yield call(userService.onboardingComplete, values);
    // const { data } = response;
    yield call(userService.onboardingComplete, values);
  } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error: ", error);
  }
}

function* changePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
  yield takeEvery(GET_USER_INFO, getUserInfo);
  yield takeEvery(ONBOARDING_COMPLETE, onboardingComplete);
}

export default changePasswordSaga;
