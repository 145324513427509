import {
  CHANGE_PASSWORD,
  FAIL_CHANGE_PASSWORD,
  GET_USER_INFO,
  ONBOARDING_COMPLETE,
  SET_CONDITION_ACCEPTED
} from "store/types";

const initialState = {
  error: "",
  user: {}
}

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ONBOARDING_COMPLETE:
      return { ...state };
    case CHANGE_PASSWORD:
      return { ...state, error: "" };
    case FAIL_CHANGE_PASSWORD:
      return { ...state, error: payload };
    case GET_USER_INFO:
      return { ...state }
    case SET_CONDITION_ACCEPTED:
      return { ...state, loading: true, error: null, conditionAccepted: true };
    default:
      return { ...state };
  }
};

export default userReducer;
