import axios from "./axios";

const getUserInfo = (dni) => axios.get(`/user/terms/${dni}`);

const changePassword = (values) => {
  const { identityNumber: id_number, password } = values;
  return axios.put("/user/change_password", { id_number, password });
};

const sendRecoveryPassMail = (id_number) => {
    return axios.post(`/user/recovery_passmail/${id_number}`);
}

const recoverPassword = (values) => {
    const { identityNumber: id_number, password } = values;
    return axios.put("/user/change_password", { id_number, password });
};

const onboardingComplete = (values) => {
  const { id_number } = values;
  return axios.post(`/user/on_boarding/${id_number}`);
};

export default {
    changePassword,
    getUserInfo,
    onboardingComplete,
    sendRecoveryPassMail,
    recoverPassword
};
