import {
  CHANGE_PASSWORD,
  FAIL_CHANGE_PASSWORD,
  GET_USER_INFO,
  ONBOARDING_COMPLETE,
  SET_CONDITION_ACCEPTED
} from "store/types";

export const changePasswordAction = (values, history) => ({
  type: CHANGE_PASSWORD,
  payload: { values, history },
});

export const failChangePasswordAction = (values) => ({
  type: FAIL_CHANGE_PASSWORD,
  payload: { values },
});

export const onboardingCompleteAction = (values) => ({
  type: ONBOARDING_COMPLETE,
  payload: { values },
});

export const getUserInfo = (values) => ({
  type: GET_USER_INFO,
  payload: { values },
})

export const setConditionAccepted = () => ({
  type: SET_CONDITION_ACCEPTED,
})