import { call, put, takeEvery } from "redux-saga/effects";
import { setCredentials } from "utils/authUtils";

import * as authActions from "../actions/authActions";
import { LOGIN_REQUEST , ACCEPT_CONDITIONS } from "../types";
import { authService , acceptConditionsService } from "services";
import * as userActions from "../actions/userActions";

function* login({ payload: { values, history } }) {
  try {
    const response = yield call(authService.login, values);
    const { data } = response;
    setCredentials(data);
    yield put(authActions.loginSuccess(data));
    if(data.onboarding_complete){
      history.push("/");
    } else {
      history.push("/onboarding");
    }
  } catch (error) {
    yield put(authActions.loginFailed(error.response));
  }
}

function* acceptTerms({ payload: { values, history } }) {
  try {
    const response = yield call(acceptConditionsService.accept, values);
    const { data } = response;
    yield put(userActions.setConditionAccepted());
  } catch (error) {
    yield put(authActions.loginFailed(error.response));
  }
}

function* authSaga() {
  yield takeEvery(ACCEPT_CONDITIONS, acceptTerms);
  yield takeEvery(LOGIN_REQUEST, login);
}

export default authSaga;
