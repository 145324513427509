import axios from "axios";
const VU_API_MIDDLEWARE=process.env.REACT_APP_API_VU_API_MIDDLEWARE

const getLinkInfo = (id) =>{
  return axios.get(`${VU_API_MIDDLEWARE}/api/links/${id}`);
} 


const getVerifications = (id) =>{
  return axios.get(`${VU_API_MIDDLEWARE}/api/verifications/${id}`);
} 

const postLink = (body) => {
  return axios.post(`${VU_API_MIDDLEWARE}/api/links`,body);
};

export default {
  getLinkInfo,
  postLink,
  getVerifications,
};
