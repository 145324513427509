import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

const authProtectedRoutes = [
  {
    path: "/messages-management",
    component: lazy(() => import("views/MessageManagementList")),
  },
  {
    path: "/messages-details/:id",
    component: lazy(() => import("views/MessageManagementDetails")),
  },
  {
    path: "login/recover-password",
    exact: true,
    component: () => <Redirect to="/login/recover-password" />,
  },
  {
    path: "/onboarding",
    exact: true,
    component: () => <Redirect to="/onboarding" />,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/messages-management" />,
  },
];

const publicRoutes = [
  { path: "/login/:dni", component: lazy(() => import("views/Login")) },

  { path: "/recover-password", component: lazy(() => import("views/RecoverPassword")) },
  { path: "/change_password/:id/:token", component: lazy(() => import("views/RecoverPassword")) },
  {
    path: "/onboarding",
    component: lazy(() => import("views/Onboarding")),
  },
  {
    path: "/messages-details/:id",
    component: lazy(() => import("views/MessageManagementDetails")),
  },
];

export { authProtectedRoutes, publicRoutes };
